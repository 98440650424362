import '../../Styles/Contact.css';
import ContactContent from './Contact Content';
import FootBanner from '../Home components/Foot Banner';

const Contact = () => {
  return (
    <>
      <ContactContent />
      <FootBanner />
    </>
  );
};

export default Contact;
